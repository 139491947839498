.startup-title{
  -webkit-text-stroke: 2px #fff;
  color: transparent;  
  white-space: nowrap;
  h2{
    font-family: BebasNeue;
    font-size: 66px;
    font-style: normal;
    font-weight: 400;
    line-height: 110px; /* 166.667% */
    letter-spacing: 3.3px;
  }
  h1{
    font-family: BebasNeue;
    font-size: 132px;
    font-style: normal;
    font-weight: 400;
    line-height: 107px;
    letter-spacing: 6.6px;
  }
}
@media (max-width:1400px) {
  .startup{
    .desk{
      display: none;
    }
  }
}
@media (max-width:1200px) {
  .startup{
    >hgroup{
      color: #04FFC9;
      font-family: BebasNeue;
      font-size: 91px;
      font-style: normal;
      font-weight: 400;
      line-height: 79px;
      width: 80vw;
      margin:auto;
      margin-top: 23px;
      text-align: center;
    }
    >div:nth-of-type(1){
      width: 80vw;
      p{
        margin-top: 51px;
        font-size: 20px;
        line-height: 150%; /* 30px */
        letter-spacing: 2px;
      }
      section{
        width: 100%;
        font-size: 14px;
        letter-spacing: 1.4px;
      }
    }
  main{
    hgroup{
      color: #FFF;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 30px */
      letter-spacing: 2px;
    }
    p{
      color: #FFF;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 24px */
      letter-spacing: 1.6px;
    }
    >div:nth-of-type(1){
      margin-bottom: 24px;
    }
    >div:nth-of-type(2){
      padding-left: 0;
      gap: 32px;
    }
  }
  main:nth-of-type(1){
    h1{
      font-family: BebasNeue;
      font-size: 86px;
      font-style: normal;
      font-weight: 400;
      line-height: 76px;
      letter-spacing: 4.3px;
    }
  }
  main:nth-of-type(2),main:nth-of-type(3){
    h1{
      font-family: BebasNeue;
      font-size: 104px;
      font-style: normal;
      font-weight: 400;
      line-height: 86px;
      letter-spacing: 5.2px;
    }
  }
  main:nth-of-type(4),main:nth-of-type(5){
    .startup-title{
      h1{
        font-family: BebasNeue;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 44px;
        letter-spacing: 2px;
      }
    }
  }
  }
}
@media (max-width:900px) {
  .startup-title{
    -webkit-text-stroke: 1px #fff;
    color: transparent;  
    white-space: nowrap;
    h2{
      font-family: BebasNeue;
      font-size: 44px;
      font-style: normal;
      font-weight: 400;
      line-height: 49px; /* 111.364% */
      letter-spacing: 2.2px;
    }
    h1{
      font-family: BebasNeue;
      font-size: 86px;
      font-style: normal;
      font-weight: 400;
      line-height: 76px;
      letter-spacing: 4.3px;
    }
  }
  .startup{
    padding-bottom: 170px;
    >div:nth-of-type(3){
      margin-top: 52px;
      width: 80vw;
      p{
        color: #FFF;
        text-align: center;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 30px */
        letter-spacing: 2px;
      }
    }
  }
}