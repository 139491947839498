.App {
  text-align: center;
}
.title{
  margin-left: 13vw;
  text-align: left;
  -webkit-text-stroke: 4px #fff;
  color: transparent;  
  white-space: nowrap;
  h2{
    font-family: BebasNeue;
    font-size: 86px;
    font-style: normal;
    font-weight: 400;
    line-height: 110px; /* 127.907% */
    letter-spacing: 4.3px;
  }
  h1{
    font-family: BebasNeue;
    font-size: 130px;
    font-style: normal;
    font-weight: 400;
    line-height: 110px;
    letter-spacing: 6.5px;
    -webkit-text-stroke: 3px #fff;
  }
}
@media (max-width:1020px) {
  .title{
    margin-left: 10vw;
    text-align: left;
    -webkit-text-stroke: 4px #fff;
    color: transparent;  
    white-space: nowrap;
    margin-bottom: 59px;
    h2{
      font-family: BebasNeue;
      font-size: 49px;
      font-style: normal;
      font-weight: 400;
      line-height: 49px;
      letter-spacing: 2.45px;
    }
    h1{
      font-family: BebasNeue;
      font-size: 66px;
      font-style: normal;
      font-weight: 400;
      line-height: 110px; /* 166.667% */
      letter-spacing: 3.3px;
    }
  }
}
@media (max-width:450px) {
  .title{
    margin-left: 6vw;
  }
}

section.title{
  -webkit-text-stroke: 3px #000;
  margin: 0;
}
hgroup.title{
  font-family: Roboto;
  font-size: 110px;
  font-style: normal;
  font-weight: 700;
  line-height: 113%; /* 124.3px */
  text-transform: uppercase;
  margin: 0;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
