#gaming{
  border-radius: 10px 0px 0px 0px;
  border: 1px solid #23C699;
  background: linear-gradient(180deg, #202F2B 0%, #212524 100%);
}
#nfts{
  border-top: 1px solid #23C699;
  border-bottom: 1px solid #23C699;
}
#ai{
  border-radius: 0px 10px 0px 0px;
  border: 1px solid #23C699;
}
#rwa{
  border-radius: 0px 0px 0px 10px;
  border-right: 1px solid #23C699;
  border-bottom: 1px solid #23C699;
  border-left: 1px solid #23C699;
}
#web3{
  border-bottom: 1px solid #23C699;
}
#personal{
  border-radius: 0px 0px 10px 0px;
  border-right: 1px solid #23C699;
  border-bottom: 1px solid #23C699;
  border-left: 1px solid #23C699;
}
.service{
  background: url(../images/servicebg.png);
  background-size: 100% 100%;
}
@media (max-width:1300px) {
  .home-five{
    padding-left: 10vw;
    padding-right: 10vw;
    >img{
      top: 50%;
      left: 0;
      transform: translate(-50%);
    }
    >p{
      width: 80vw;
    }
    >div{
      flex-direction: column;
      >div{
        margin-top: 27px;
        >p:nth-of-type(2){
          width: 80vw;
        }
      }
    }
  }
  .home-seven{
    .title{
      -webkit-text-stroke: 1px #fff;
      h2{
        font-family: BebasNeue;
        font-size: 69px;
        font-style: normal;
        font-weight: 400;
        line-height: 72px; /* 57.971% */
        letter-spacing: 3.45px;
        -webkit-text-stroke: 1px #fff;
      }
      h1{
        font-family: BebasNeue;
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: 1.9px;
        -webkit-text-stroke: 1px #fff;
      }
    }
    >div:nth-of-type(2){
      width: 80vw;
    }
    main{
      flex-direction: column;
      section{
        border-right: 1px solid #23C699;
        border-left: 1px solid #23C699;
        border-bottom: 1px solid #23C699;
        gap: 16px;
      }
      section:nth-of-type(1){
        border-radius: 10px 10px 0px 0px!important;
        border: 1px solid #23C699;
        background: linear-gradient(180deg, #202F2B 0%, #212524 100%);
      }
      section:nth-last-of-type(1){
        border-radius: 0px 0px 10px 10px!important;
        border-right: 1px solid #23C699;
        border-bottom: 1px solid #23C699;
        border-left: 1px solid #23C699;
      }
    }
  }
  .home-eight{
    .title{
      margin-bottom: 0;
      -webkit-text-stroke: 1px #fff;
      h1{
        font-family: BebasNeue;
        font-size: 100px;
        font-style: normal;
        font-weight: 400;
        line-height: 87px;
        letter-spacing: 5px;
      }
      h2{
        font-family: BebasNeue;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 110px; /* 229.167% */
        letter-spacing: 2.4px;
      }
    }
    >div:nth-of-type(2){
      width: 80vw;
      padding: 0;
      background: none;
      margin-bottom: 0;
      img{
        display: none;
      }
      >div{
        width: 100%;
        gap: 16px;
        >div{
          padding: 8px 12px;
        }
      }
    }
    >div:nth-of-type(3){
      gap: 10px;
      main{
        flex-direction: column;
        section{
          width: 80vw;
          min-width: 376px;
        }
      }
    }
  }
}
@media (max-width:1070px) {
  .home-six-desk{
    display: none;
  }
  .home-six-mobile{
    display: flex!important;
  }
  .home-night{
    >div{
      width: 80vw;
      >div{
        >div{
          gap: 16px;
          flex-wrap: wrap;
          img{
            width: 45%;
          }
        }
      }
    }
    hgroup{
      color: #FFF;
      text-align: center;
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 36px */
      letter-spacing: 2.4px;
    }
  }
}
@media (max-width:1020px) {
  .home-three{
    >div:nth-of-type(2){
      gap: 108px;
    }
    .vision-item{
      flex-direction: column;
      gap: 32px;
      width: 80vw;
      >div{
        width: 100%;
        gap: 16px;
      }
      p:nth-of-type(1){
        font-size: 20px;
        letter-spacing: 2px;
      }
    }
    .vision-item:nth-of-type(2){
      flex-direction: column-reverse;
    }
  }
}
@media (max-width:960px) {
  .home-second{
    >ul{
      gap: 32px;
      flex-wrap: wrap;
    }
    h2{
      font-size: 24px;
      letter-spacing: 2.4px;
    }
    >div{
      width: 300px;
    }
  }
  .home-five{
    >img{
      width: 200px;
      height: 200px;
    }
  }
}
@media (max-width:730px) {
  .home-frist{
    height: 433px;
    padding-top: 26px;
    >p{
      margin: 0;
    }
    h1{
      font-size: 128px;
      line-height: 150%;
      margin: 0;
    }
    >div{
      width: 300px;
      >p{
        font-size: 16px;
        letter-spacing: 1.6px;
      }
    }
  }
  .home-four{
    flex-direction: column;
    gap: 24px;
    ul{
      display: flex;
    }
    >div:nth-of-type(1){
      overflow: hidden;
      section{
        display: flex;
      }
      >div{
        width: 100vw;
        img{
          width: 100vw;
        }
      }
    }
    >div:nth-of-type(2){
      display: none;
    }
  }
  .home-five{
    >img{
      width: 134px;
      height: 134px;
    }
    >hgroup{
      font-size: 24px;
      letter-spacing: 2.4px;
    }
    .title{
      -webkit-text-stroke: 2px #000;
    }
    >div{
      >div{
        >p:nth-of-type(1){
          margin-bottom: 10px;
        }
      }
    }
  }
  .home-six-mobile{
    li{
      width: 40vw;
    }
    .title{
      font-size: 54px;
      font-weight: 700;
      line-height: 113%; /* 61.02px */
      text-transform: uppercase;
    }
    p{
      color: #FFF;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }
  }
  .home-seven{
    main{
      flex-direction: column;
      img{
        width: 45px;
        height: 40px;
      }
      span{
        font-size: 20px;
        letter-spacing: 2px;
      }
      p{
        font-size: 14px;
      }
    }
  }
}