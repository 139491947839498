@media (max-width:1020px) {
  .footer{
    flex-direction: column;
    gap: 64px;
  }
}
@media (max-width:550px) {
  .footer{
    >div:nth-of-type(1){
      img{
        width: 258px;
        height: 132px;
        margin-bottom: 64px;
      }
      p{
        background: #fff;
      }
    }
    >div:nth-of-type(2){
      width: 80vw;
      gap: 0;
      justify-content: space-between;
    }
  }
}