.header{
  background: rgba(22, 22, 22, 0.50);
  backdrop-filter: blur(10px);
}
.header-logo{
  width: 152px;
  height: 77px;
}
.header-desk{
  display: flex;
}
.header-mobile{
  display: none;
}
@media (max-width:1275px) {
  .header-logo{
    width: 87px;
    height: 44px;
  }
  .header-desk{
    display: none;
  }
  .header-mobile{
    display: flex;
  }
}